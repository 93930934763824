/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if(Object.keys(defaultFields).length === 0)
        defaultFields = {
            Name: Field.Text(
                "Name",
                $t("ADS_PUBLICATION_fieldLabel")
            ),
            Description: Field.MultiLineText(
                "Description",
                $t("ADS_PUBLICATION_fieldDescription")
            ),
            Host: Field.Text("Host", $t("ADS_PUBLICATION_fieldFtpHost")),
            Username: Field.Text("Username", $t("ADS_PUBLICATION_fieldFtpUsername")),
            Password: Field.Text("Password", $t("ADS_PUBLICATION_fieldFtpPassword")),
            DistantFolder: Field.Text("DistantFolder",
                $t("ADS_PUBLICATION_fieldFtpRemoteDirectory")),
            Port: Field.Text("Port", $t("ADS_PUBLICATION_fieldFtpPort")),
            SerializerId: Field.Number("SerializerId",
                $t("ADS_PUBLICATION_fieldSiteIdentifier")),
            Format: Field.Selection(
                "Format",
                $t("ADS_PUBLICATION_fieldExportFormat")
            ),
            Encoding: Field.Selection(
                "Encoding",
                $t("ADS_PUBLICATION_fieldEncoding")
            ),
            // File: Field.File("File", $t("ADS_PUBLICATION_fieldLogo")),

            isFtpMode: Field.Boolean(
                "isFtpMode",
                $t("ADS_PUBLICATION_fieldExportType")
            ),
            
            // FTP Mode options
            isPassiveMode: Field.Boolean(
                "isPassiveMode",
                $t("ADS_PUBLICATION_fieldFtpPassiveMode")
            ),
            isSslMode: Field.Boolean(
                "isSslMode",
                $t("ADS_PUBLICATION_fieldFtpSslMode")
            ),
            
            // Export options
            isAutoExport: Field.Boolean(
                "isAutoExport",
                $t("ADS_PUBLICATION_fieldAutoExport")
            ),
            isMutualizedExport: Field.Boolean(
                "isMutualizedExport",
                $t("ADS_PUBLICATION_fieldMutualizedExport")
            ),
            
            // Contact management
            hasEditableContacts: Field.Boolean(
                "hasEditableContacts",
                $t("ADS_PUBLICATION_fieldEditableContacts")
            ),
            Customers: Field.IdentifiableSet(
                "Customers",
                $t( "ADS_PUBLICATION_fieldEditableGroups" )
            )
            // contactSource: Field.Selection(
            //     "contactSource",
            //     $t("ADS_PUBLICATION_fieldContactManagement"),
            //     [
            //         { id: "negotiator", label: $t("ADS_PUBLICATION_contactSourceNegotiator") },
            //         { id: "agency", label: $t("ADS_PUBLICATION_contactSourceAgency") },
            //         { id: "company", label: $t("ADS_PUBLICATION_contactSourceCompany") }
            //     ]
            // )
        };

    return defaultFields;
}

export default {
    Get
};
