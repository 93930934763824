


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FileUpload extends Vue {
    @Prop() public Id!: string;
    @Prop() public Placeholder!: string;
    @Prop() public Value!: string;

    @Prop( { default: "image/*" } ) 
    public Accept!: string; // e.g. "image/*" or ".pdf"


    @Prop({ default: false })
    public Multiple!: boolean;

    @Prop({ default: false })
    Readonly!: boolean;

    private file: File | null = null;

    private handleFileChange(event: InputEvent) {
        const target = event.target as HTMLInputElement;
        if(target && target.files) {
            const files = target.files;
            if(files.length > 0) {
                this.file = files[0];
            }
            this.$emit("Value", this.file);
        }
    }


    private removeFile() {
        this.file = null;
        this.$emit("Value", this.file);
    }
}
