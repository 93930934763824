


import {
    Component,
    Emit,
    Prop,
    Ref,
    Vue
} from "vue-property-decorator";

import { CustomError, Field, PagerSorter, TextFilter } from "@Internal/Models";
import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "@Internal/Contracts";
import { $t, DeepCopy, EventBus } from "App/IOC";
import { NewPlan, Plan, Product } from "App/Models";
import { Plans } from "App";

import PopupWindow from "Web/Components/Popup/Window.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import ColorInput from "Web/Components/Common/Input/Color.vue";
import BooleanField from "Web/Components/Common/Input/Boolean.vue";

@Component({
    components: {
        BooleanField,
        PopupWindow,
        ColorInput,
        Form,
        FormField
    }
})
export default class PlanForm extends Vue {
    @Prop() readonly Record?: Plan;

    @Prop()
    readonly Product!: Product;

    @Ref( "form" )
    private readonly form?: Form;

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: "",
        Active: false
    };

    private fields: Dictionary<Field> = {};
    private record: Plan = NewPlan();
    private showPriceForm: boolean = false;

    async created(): Promise<void> {
        this.action.Name = $t(
            this.edit ? "PLANS_confirmEdit" : "PLANS_confirmCreate"
        );

        if( this.Record !== undefined ) {
            this.record = DeepCopy( this.Record ) as Plan;

            for( const plan of this.record.CompatiblePlans )
                plan.Name = ( plan.Product as Product ).Name + " - " + plan.Name;
        }

        this.fields = await Plans.Fields.Get();
    }

    private canCreate(): void {
        if( this.form === undefined )
            return;

        const record: Plan = this.form.GetRecord() as Plan;

        this.action.Active = record.Name !== undefined && /\S/.test( record.Name );
    }

    private create(): void {
        if( this.form === undefined )
            return;

        this.$emit( "Create", this.form.GetRecord() );
        this.close();
    }

    @Emit( "Close" )
    private close(): void {}

    private get edit(): boolean {
        return this.Record !== undefined;
    }

    private async getPlans( keyword?: string ): Promise<[ Plan[], CustomError | undefined ]> {
        if( keyword === undefined )
            return [ [], undefined ];

        const filter = new TextFilter( "Name", "" );
        filter.Value = [ keyword ];

        const [ plans, , error ] = await Plans.Find( [ filter ], new PagerSorter( 999 ) );

        /* Filter out plans from the same product as the current plan, as leaving them
           doesn't make sense business wise. */
        const compatiblePlans = plans.filter(
            ( plan: Plan ): boolean => plan.Product.Id !== this.Product.Id
        );

        /* Format plan names with their respective product as prefix. */
        for( const plan of compatiblePlans )
            plan.Name = plan.Product.Name + " - " + plan.Name;

        console.log( "compatiblePlans", compatiblePlans );

        return [ compatiblePlans, error ];
    }

    private checkTrialViability(
        value: boolean,
        update: ( value: boolean ) => void
    ): void {
        const otherPlans = this.Product.Plans.filter(
            ( p: Plan ): boolean => p.Id !== this.record.Id
        );

        /* If we want to set this plan as the trial plan, we must make sure there is no other
           existing trial plan. */
        if( value && otherPlans.some( ( plan: Plan ): boolean => plan.Trial ) ) {
            EventBus.Publish( "Error", CustomError.User( $t( "PLANS_trialPlanExist" ) ) );

            return;
        }

        /* If this plan used to be a trial and we want to convert it into a paid plan, there
           must not be more than 3 plans already, otherwise you will end up with 4 paid plans. */
        if(
            !value &&
            ( otherPlans.length >= 3 ) &&
            otherPlans.every( ( plan: Plan ): boolean => !plan.Trial )
        ) {
            EventBus.Publish( "Error", CustomError.User( $t( "PLANS_trialPlanDoesNotExist" ) ) );

            return;
        }

        update( value );
    }
}
