/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */
/* eslint-disable max-len */
export default {
    ADS_PUBLICATION_title: "Portails d'annonces",
    ADS_PUBLICATION_create: "Ajouter un portail",
    ADS_PUBLICATION_add: "Ajouter",
    ADS_PUBLICATION_ftpCreate: "Créer un portail",
    ADS_PUBLICATION_confirmCreate: "Ajouter le portail",
    ADS_PUBLICATION_ftpUpdate: "Mettre à jour le portail",
    ADS_PUBLICATION_confirmUpdate: "Mise à jour du portail",

    ADS_PUBLICATION_createSuccessTitle: "Notification",
    ADS_PUBLICATION_createSuccessMessage: "Nouveau portail a été créé avec succés",
    ADS_PUBLICATION_createUpdateMessage: "Le portail a été mis à jour avec succès",
    ADS_PUBLICATION_createDeleteMessage: "Le portail a been supprimé avec succès",
    ADS_PUBLICATION_UpdateCustomerMessage: "Groupe de clients a été mis à jour avec succès",
    ADS_PUBLICATION_createCustomerMessage: "Groupe de clients a été créé avec succès",

    CUSTOMERS_actionFtp: "FTP",
    CUSTOMERS_actionApi: "API",

    ADS_PUBLICATION_columnId: "ID",
    ADS_PUBLICATION_columnLogo: "Logo",
    ADS_PUBLICATION_columnImage: "Image",
    ADS_PUBLICATION_columnName: "Nom",
    ADS_PUBLICATION_columnType: "Type",
    ADS_PUBLICATION_columnDescription: "Description",
    ADS_PUBLICATION_columnSerializer: "Serializer",
    ADS_PUBLICATION_columnCode: "Code envoyé",
    ADS_PUBLICATION_columnSubscription: "Abonnement",
    ADS_PUBLICATION_columnNumberOfAds: "Nbre annonces",
    ADS_PUBLICATION_columnStatus: "Statut",
    ADS_PUBLICATION_columnAutoStatus: "Diffusion auto",
    ADS_PUBLICATION_columnLastSend: "Dernier envoi",
    ADS_PUBLICATION_columnStartDate: "Date de début",
    ADS_PUBLICATION_columnOnlineSubscription: "Abonnement en ligne",
    ADS_PUBLICATION_columnOwner: "Propriétaire",
    ADS_PUBLICATION_columnSubType: "Sous-type",
    ADS_PUBLICATION_columnZipCode: "CP",
    ADS_PUBLICATION_columnCategory: "Catégorie",
    ADS_PUBLICATION_columnNoOfRooms: "Nombre pièces",
    ADS_PUBLICATION_columnPrice: "Prix",
    ADS_PUBLICATION_columnMandate: "Mandat",
    ADS_PUBLICATION_columnCreated: "Créé le",

    ADS_PUBLICATION_splashScreenTitle: "Créez votre premier portails d'annonces",
    ADS_PUBLICATION_splashScreenDescription: "Créez et automatisez des annonces..",

    ADS_PUBLICATION_listOfAdsTitle: "Liste des portails d'annonces",
    ADS_PUBLICATION_listOfAdsDescription: "Vous trouverez la liste des portails d'annonces disponibles sur votre compte et non activés.",

    ADS_PUBLICATION_newAdsPortalsTitle: "Ajouter un portail d'annonces",
    ADS_PUBLICATION_newAdsPortalsCreate: "Ajouter le portail d'annonces",
    ADS_PUBLICATION_newAdsPortalsUpdate: "Modifier le portail d'annonces",
    ADS_PUBLICATION_newAdsPortalsCancel: "Annuler",
    ADS_PUBLICATION_modalUpdateTitle: "Modifier un portail d'annonces",


    ADS_PUBLICATION_fieldSiteName: "Nom du site",
    ADS_PUBLICATION_fieldContactEmail: "Contact email service clients",
    ADS_PUBLICATION_fieldIdentifierSent: "Identifiant envoyé",
    ADS_PUBLICATION_fieldBroadcastStatus: "Diffusion automatique",
    ADS_PUBLICATION_fieldGroups: "Afficher le portail sur le(s) groupe(s):",

    // ftp form
    ADS_PUBLICATION_fieldLabel: "Libellé",
    ADS_PUBLICATION_fieldStatus: "Statut",
    ADS_PUBLICATION_fieldExportFormat: "Format d'export",
    ADS_PUBLICATION_fieldEncoding: "Encodage",
    ADS_PUBLICATION_fieldExportType: "Type d'export",
    ADS_PUBLICATION_fieldSiteIdentifier: "Identifiant du site",
    ADS_PUBLICATION_fieldAffiliateIdentifier: "Identifiant Affilié",
    ADS_PUBLICATION_fieldDescription: "Description",
    ADS_PUBLICATION_fieldLogo: "Logo",
    ADS_PUBLICATION_fieldQuota: "Quota (Transellis XML)",
    ADS_PUBLICATION_fieldFtpHost: "FTP Hôte (serveur)",
    ADS_PUBLICATION_fieldFtpUsername: "FTP Nom d'utilisateur",
    ADS_PUBLICATION_fieldFtpPassword: "FTP Mot de passe",
    ADS_PUBLICATION_fieldFtpRemoteDirectory: "FTP Répertoire distant",
    ADS_PUBLICATION_fieldFtpPort: "FTP Port",
    ADS_PUBLICATION_fieldFtpMode: "FTP Mode passif",
    ADS_PUBLICATION_fieldFtpModeSsl: "FTP Mode SSL (FTPS)",

    // New translations for FTP and export options
    ADS_PUBLICATION_fieldFtpPassiveMode: "Mode passif (par défaut le mode passif doit être activé)",
    ADS_PUBLICATION_fieldFtpSslMode: "Mode SSL (FTPS)",
    ADS_PUBLICATION_fieldAutoExport: "Exporter automatiquement",
    ADS_PUBLICATION_fieldMutualizedExport: "Export mutualisé (envoi mutualisé sur plusieurs société)",
    ADS_PUBLICATION_fieldEditableContacts: "Contacts éditables",
    ADS_PUBLICATION_fieldEditableGroups: "Afficher le portail sur le(s) groupe(s)",
    
    // Contact management translations
    ADS_PUBLICATION_fieldContactManagement: "Gestion des contacts",
    ADS_PUBLICATION_contactSourceNegotiator: "Utiliser les coordonnées du négociateurs",
    ADS_PUBLICATION_contactSourceAgency: "Utiliser les coordonnées de l'agence (par défaut)",
    ADS_PUBLICATION_contactSourceCompany: "Utiliser les coordonnées de la société",

    ADS_PUBLICATION_statusActive: "Activé",
    ADS_PUBLICATION_statusInactive: "Désactivé",

    ADS_PUBLICATION_statusZip: "Zip",
    ADS_PUBLICATION_statusText: "Text",
    ADS_PUBLICATION_statusUtf8: "UtF-8",
    ADS_PUBLICATION_statusLatin: "Latin",

    ADS_PUBLICATION_formSeloger: "Seloger",
    ADS_PUBLICATION_formPaid: "Payant",
    ADS_PUBLICATION_formSiteNameValue: "Se loger",
    ADS_PUBLICATION_formContactEmailLabel: "Contact email service clients",
    ADS_PUBLICATION_formContactEmailValue: "support@seloger.com",
    ADS_PUBLICATION_formDescriptionValue: "Site national d'annonces immobilières",

    ADS_PUBLICATION_actionActivetor: "Activer",
    ADS_PUBLICATION_actionActivetorMessage: "Êtes-vous sûr(e) de activer ce portail d'annonce ?",
    ADS_PUBLICATION_actionDeactivate: "Désactiver",
    ADS_PUBLICATION_actionDeactivateMessage: "Êtes-vous sûr(e) de désactiver ce portail d'annonce ?",
    ADS_PUBLICATION_actionModify: "Modifier",
    ADS_PUBLICATION_actionDeleteMessage: "Vous êtes sur le point de supprimer la diffusion ?",
    ADS_PUBLICATION_actionOk: "ok",
    ADS_PUBLICATION_actionUpdateMessage: "Vous devez renseigner l'Identifiant envoyé",
    
    ADS_PUBLICATION_deletedSuccessfully: "Portails d'annonces a été supprimé avec succès",
    ADS_PUBLICATION_updatedSuccessfully: "Portails d'annonces mis à jour avec succès",
    ADS_PUBLICATION_createdSuccessfully: "Portails d'annonces mis à jour avec succès",

    ADS_PUBLICATION_firstGroup: "Afficher le portail sur le(s) groupe(s)",
    ADS_PUBLICATION_GroupOne: "Frédéric Eppler - eppler@transellis (Central agency)",
    ADS_PUBLICATION_GroupTwo: "Frédéric Eppler - contact@transellis (Central agency)",

    ADS_PUBLICATION_imageAlt: "Image",

    ADS_PUBLICATION_listOfAdsLogTitle: "Annonces Se Loger",
    ADS_PUBLICATION_annonces_name: "Annonces",
    ADS_PUBLICATION_actionDelete: "Supprimer",
    ADS_PUBLICATION_deleteConfirm: "Êtes-vous certain de vouloir supprimer ce portails d'annonces ?",
    ADS_PUBLICATION_deleteImageConfirm: "Êtes-vous certain de vouloir supprimer cette image ?"
}

