export default {
    ADS_PUBLICATION_title: "Ads Portals",
    ADS_PUBLICATION_create: "Add a portal",
    ADS_PUBLICATION_add: "Add",
    ADS_PUBLICATION_ftpCreate: "Create a portal",
    ADS_PUBLICATION_confirmCreate: "Add the portal",
    ADS_PUBLICATION_ftpUpdate: "Update the portal",
    ADS_PUBLICATION_confirmUpdate: "Portal update",

    ADS_PUBLICATION_createSuccessTitle: "Notification",
    ADS_PUBLICATION_createSuccessMessage: "New portal has been successfully created",
    ADS_PUBLICATION_createUpdateMessage: "The portal has been successfully updated",
    ADS_PUBLICATION_createDeleteMessage: "The portal has been successfully deleted",
    ADS_PUBLICATION_UpdateCustomerMessage: "Customer group has been successfully updated",
    ADS_PUBLICATION_createCustomerMessage: "Customer group has been successfully created",

    CUSTOMERS_actionFtp: "FTP",
    CUSTOMERS_actionApi: "API",

    ADS_PUBLICATION_columnId: "ID",
    ADS_PUBLICATION_columnLogo: "Logo",
    ADS_PUBLICATION_columnImage: "Image",
    ADS_PUBLICATION_columnName: "Name",
    ADS_PUBLICATION_columnType: "Type",
    ADS_PUBLICATION_columnDescription: "Description",
    ADS_PUBLICATION_columnSerializer: "Serializer",
    ADS_PUBLICATION_columnCode: "Sent code",
    ADS_PUBLICATION_columnSubscription: "Subscription",
    ADS_PUBLICATION_columnNumberOfAds: "No. of ads",
    ADS_PUBLICATION_columnStatus: "Status",
    ADS_PUBLICATION_columnAutoStatus: "Auto broadcasting",
    ADS_PUBLICATION_columnLastSend: "Last send",
    ADS_PUBLICATION_columnStartDate: "Start date",
    ADS_PUBLICATION_columnOnlineSubscription: "Online subscription",
    ADS_PUBLICATION_columnOwner: "Owner",
    ADS_PUBLICATION_columnSubType: "Subtype",
    ADS_PUBLICATION_columnZipCode: "Zip code",
    ADS_PUBLICATION_columnCategory: "Category",
    ADS_PUBLICATION_columnNoOfRooms: "Number of rooms",
    ADS_PUBLICATION_columnPrice: "Price",
    ADS_PUBLICATION_columnMandate: "Mandate",
    ADS_PUBLICATION_columnCreated: "Created on",

    ADS_PUBLICATION_splashScreenTitle: "Create your first ad portal",
    ADS_PUBLICATION_splashScreenDescription: "Create and automate ads...",

    ADS_PUBLICATION_listOfAdsTitle: "List of ad portals",
    ADS_PUBLICATION_listOfAdsDescription: 
    "Here is the list of ad portals available in your account and not activated.",

    ADS_PUBLICATION_newAdsPortalsTitle: "Add an ad portal",
    ADS_PUBLICATION_newAdsPortalsCreate: "Add the ad portal",
    ADS_PUBLICATION_newAdsPortalsUpdate: "Edit the ad portal",
    ADS_PUBLICATION_newAdsPortalsCancel: "Cancel",
    ADS_PUBLICATION_modalUpdateTitle: "Edit an ad portal",

    ADS_PUBLICATION_fieldSiteName: "Site name",
    ADS_PUBLICATION_fieldContactEmail: "Customer service contact email",
    ADS_PUBLICATION_fieldIdentifierSent: "Sent identifier",
    ADS_PUBLICATION_fieldBroadcastStatus: "Automatic broadcasting",
    ADS_PUBLICATION_fieldGroups: "Display the portal in group(s):",

    // FTP form
    ADS_PUBLICATION_fieldLabel: "Label",
    ADS_PUBLICATION_fieldStatus: "Status",
    ADS_PUBLICATION_fieldExportFormat: "Export format",
    ADS_PUBLICATION_fieldEncoding: "Encoding",
    ADS_PUBLICATION_fieldExportType: "Export type",
    ADS_PUBLICATION_fieldSiteIdentifier: "Site identifier",
    ADS_PUBLICATION_fieldAffiliateIdentifier: "Affiliate identifier",
    ADS_PUBLICATION_fieldDescription: "Description",
    ADS_PUBLICATION_fieldLogo: "Logo",
    ADS_PUBLICATION_fieldQuota: "Quota (Transellis XML)",
    ADS_PUBLICATION_fieldFtpHost: "FTP Host (server)",
    ADS_PUBLICATION_fieldFtpUsername: "FTP Username",
    ADS_PUBLICATION_fieldFtpPassword: "FTP Password",
    ADS_PUBLICATION_fieldFtpRemoteDirectory: "FTP Remote directory",
    ADS_PUBLICATION_fieldFtpPort: "FTP Port",
    ADS_PUBLICATION_fieldFtpMode: "FTP Passive mode",
    ADS_PUBLICATION_fieldFtpModeSsl: "FTP SSL Mode (FTPS)",

    // New translations for FTP and export options
    ADS_PUBLICATION_fieldFtpPassiveMode: 
    "Passive mode (by default, passive mode should be enabled)",
    ADS_PUBLICATION_fieldFtpSslMode: "SSL Mode (FTPS)",
    ADS_PUBLICATION_fieldAutoExport: "Export automatically",
    ADS_PUBLICATION_fieldMutualizedExport: 
    "Mutualized export (shared sending across multiple companies)",
    ADS_PUBLICATION_fieldEditableContacts: "Editable contacts",
    ADS_PUBLICATION_fieldEditableGroups: "Display the portal on the group(s)",
    
    // Contact management translations
    ADS_PUBLICATION_fieldContactManagement: "Contact management",
    ADS_PUBLICATION_contactSourceNegotiator: "Use negotiator's contact details",
    ADS_PUBLICATION_contactSourceAgency: "Use agency contact details (default)",
    ADS_PUBLICATION_contactSourceCompany: "Use company contact details",

    ADS_PUBLICATION_statusActive: "Active",
    ADS_PUBLICATION_statusInactive: "Inactive",

    ADS_PUBLICATION_statusZip: "Zip",
    ADS_PUBLICATION_statusText: "Text",
    ADS_PUBLICATION_statusUtf8: "UTF-8",
    ADS_PUBLICATION_statusLatin: "Latin",

    ADS_PUBLICATION_formSeloger: "Seloger",
    ADS_PUBLICATION_formPaid: "Paid",
    ADS_PUBLICATION_formSiteNameValue: "Se loger",
    ADS_PUBLICATION_formContactEmailLabel: "Customer service contact email",
    ADS_PUBLICATION_formContactEmailValue: "support@seloger.com",
    ADS_PUBLICATION_formDescriptionValue: "National real estate ad site",

    ADS_PUBLICATION_actionActivetor: "Activate",
    ADS_PUBLICATION_actionActivetorMessage: 
    "Are you sure you want to activate this ad portal?",
    ADS_PUBLICATION_actionDeactivate: "Deactivate",
    ADS_PUBLICATION_actionDeactivateMessage: 
    "Are you sure you want to deactivate this ad portal?",
    ADS_PUBLICATION_actionModify: "Modify",
    ADS_PUBLICATION_actionDeleteMessage: 
    "Are you sure you want to delete the broadcast?",
    ADS_PUBLICATION_actionOk: "OK",
    ADS_PUBLICATION_actionUpdateMessage: "You must provide the sent identifier",
    
    ADS_PUBLICATION_deletedSuccessfully: "Ad portal has been successfully deleted",
    ADS_PUBLICATION_updatedSuccessfully: "Ad portal has been successfully updated",
    ADS_PUBLICATION_createdSuccessfully: "Ad portal has been successfully updated",

    ADS_PUBLICATION_firstGroup: "Display the portal in group(s)",
    ADS_PUBLICATION_GroupOne: "Frédéric Eppler - eppler@transellis (Central agency)",
    ADS_PUBLICATION_GroupTwo: "Frédéric Eppler - contact@transellis (Central agency)",

    ADS_PUBLICATION_imageAlt: "Image",

    ADS_PUBLICATION_listOfAdsLogTitle: "Se Loger Ads",
    ADS_PUBLICATION_annonces_name: "Ads",
    ADS_PUBLICATION_actionDelete: "Delete",
    ADS_PUBLICATION_deleteConfirm: "Are you sure you want to delete this ad portal?",
    ADS_PUBLICATION_deleteImageConfirm: "Are you sure you want to delete this image?"
}
