/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* © IBS Group. See COPYRIGHT file for full copyright & licensing details. */

import Feathers from "@feathersjs/feathers";
import { Autobind } from "@Internal/Utils";

import { AnnotatedJSON, Dictionary, Filter, HandleError, Identifiable } from "@Internal/Contracts";
import { CustomError, PagerSorter } from "@Internal/Models";
import { Models } from "@Internal";
import { EventBus } from "App/IOC";
import { CountDifferentFields } from "./Base";

function CompileFilters(filters: Filter[]): Dictionary {
    const result: Dictionary = {};

    filters.forEach((filter: Filter): void => {
        result[filter.Id] = filter.FormatValue().toString();
    });

    return result;
}

export async function ProcessException(exception: any): Promise<CustomError> {
    let customError: CustomError;

    /* Hack: To remove when refresh token logic is implemented. */
    if(exception.name === "NotAuthenticated")
        EventBus.Publish("InvalidAuthorization");

    if(exception.Id !== undefined)
        customError = new CustomError(exception.Id, exception.Message);

    else
        customError = new CustomError("BaseService.Get", String(exception));

    return customError;
}

export default class AdsPublicationService<T extends Identifiable> {
    private service: Feathers.Service<any>;
    private imageService: Feathers.Service<any>;
    private customerService: Feathers.Service<any>;

    constructor(service: Feathers.Service<any>, 
        imageService: Feathers.Service<any>, 
        customerService: Feathers.Service<any>) {
        this.service = service;
        this.imageService = imageService;
        this.customerService = customerService;
    }

    // eslint-disable-next-line max-len
    public async AddPicture(id: string, file: File): Promise<[any | undefined, CustomError | undefined]> {

        try {
            const results = await this.service.addPicture(id, file);
            return this.deserialize( results );

        } catch( error ) {
            console.log("error", error);
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    public async DeletePicture(id: string): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const record = await this.imageService.remove( id);

            return this.deserialize( record );
        } catch( error ) {
            console.log("error", error);
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Create( record: T ): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const results = await this.service.create( this.serialize( record, true ) );

            return this.deserialize( results );

        } catch( error ) {
            console.log("error", error);
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Find(
        filters: Filter[],
        pagerSorter: PagerSorter
    ): Promise<[T[], PagerSorter, CustomError | undefined]> {
        const query: Dictionary = {
            ...CompileFilters(filters),
            Page: pagerSorter.Page,
            Limit: pagerSorter.Limit
        }

        if(pagerSorter.Column !== undefined) {
            query.Sort = pagerSorter.Column.Id;
            query.Descending = pagerSorter.Descending;
        }

        let results: any;

        try {
            results = await this.service.find({ query });

        } catch(error) {
            return [[], pagerSorter, await ProcessException(error)];
        }

        if(results.Error !== undefined)
            return [[], pagerSorter, new CustomError(results.Error.Id, results.Error.Message)];

        pagerSorter = pagerSorter.Copy();
        // pagerSorter.Total = results.Pagination.Total;
        if(results.Pagination && results.Pagination.Total) {
            pagerSorter.Total = results.Pagination.Total;
        } else {
            pagerSorter.Total = 0;
        }

        const ready: T[] = [];

        if(results.Items !== undefined) {
            for(const record of results.Items) {
                const [instance, error] = this.deserialize(record);
                if(error !== undefined)
                    return [[], pagerSorter, error];

                if(instance !== undefined) {
                    const result = {
                        ...instance,
                        Id: record.id
                    }
                    ready.push(result as T);
                }
            }
        }

        if(filters.length > 0 || pagerSorter.Limit === 1)
            return [ready, pagerSorter, undefined];

        return [ready, pagerSorter, undefined];
    }

    @Autobind
    async Get( id: number ): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const record = await this.service.get( id );

            if( record === undefined )
                return [ undefined, undefined ];

            return this.deserialize( record );

        } catch( error ) {
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async Update(
        originalRecord: T,
        updatedRecord: T
    ): Promise<[ T | undefined, CustomError | undefined ]> {
        const record = this.countDifferentFields( originalRecord, updatedRecord );

        if( Object.keys( record as {} ).length === 0 )
            return [ undefined, new CustomError( -1, $t( "GLOBAL_nothingToUpdate" ) ) ];

        const payload = this.serialize( record, true );

        /* HACK: `undefined` values are unfortunately ommitted by JSON.stringify which is used
        by Featherjs REST client, so we force any `undefined` value to `null` so that
        it can be sent. */
        const replacer = ( key: string, value: any ): boolean =>
            typeof value === "undefined" ? null : value;

        let results: any;

        try {
            results = await this.service.update(
                originalRecord.Id,
                JSON.parse( JSON.stringify( payload, replacer ) )
            );

        } catch( error ) {
            console.log( error );

            return [ undefined, await ProcessException( error ) ];
        }

        return this.deserialize( results );
    }

    @Autobind
    async GetAllCustomers(
        filters: Filter[],
        pagerSorter: PagerSorter
    ): Promise<[T[], PagerSorter, CustomError | undefined]> {
        const query: Dictionary = {
            ...CompileFilters(filters),
            Page: pagerSorter.Page,
            Limit: pagerSorter.Limit
        }

        if(pagerSorter.Column !== undefined) {
            query.Sort = pagerSorter.Column.Id;
            query.Descending = pagerSorter.Descending;
        }

        let results: any;

        try {
            results = await this.customerService.find({ query });

        } catch(error) {
            return [[], pagerSorter, await ProcessException(error)];
        }

        if(results.Error !== undefined)
            return [[], pagerSorter, new CustomError(results.Error.Id, results.Error.Message)];

        pagerSorter = pagerSorter.Copy();
        // pagerSorter.Total = results.Pagination.Total;
        if(results.Pagination && results.Pagination.Total) {
            pagerSorter.Total = results.Pagination.Total;
        } else {
            pagerSorter.Total = 0;
        }

        const ready: T[] = [];

        if(results.Items !== undefined) {
            for(const record of results.Items) {
                const [instance, error] = this.deserialize(record);
                if(error !== undefined)
                    return [[], pagerSorter, error];

                if(instance !== undefined) {
                    const name = record.company !== undefined ? 
                        `${record.company} - ${record.name || ""}` 
                        : record.name || "";
                    
                    const result: any = {
                        Id: record.id,
                        Name: name,
                        Group: record.group || ""
                    }
                    ready.push(result as T);
                }
            }
        }

        if(filters.length > 0 || pagerSorter.Limit === 1)
            return [ready, pagerSorter, undefined];

        return [ready, pagerSorter, undefined];
    }
    @Autobind
    async CustomerCreate( record: T ): Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const results = await this.customerService.create( this.serialize( record, true ) );

            return this.deserialize( results );

        } catch( error ) {
            console.log("error", error);
            return [ undefined, await ProcessException( error ) ];
        }
    }

    @Autobind
    async DeleteCustomer( id: number, record: T ): 
    Promise<[ T | undefined, CustomError | undefined ]> {
        try {
            const results = 
            await this.customerService.update(id, this.serialize( record, true ) );

            return this.deserialize( results );

        } catch( error ) {
            console.log("error", error);
            return [ undefined, await ProcessException( error ) ];
        }
    }

    protected deserialize(result: AnnotatedJSON): HandleError<T> {
        return Models.Deserialize<T>(result);
    }

    protected serialize<T>( record: T, idOnly?: boolean ): AnnotatedJSON {
        return Models.Serialize( record, idOnly );
    }
    private countDifferentFields( originalRecord: T, updatedRecord: T ): T {
        return CountDifferentFields<T>( originalRecord, updatedRecord );
    }
}
