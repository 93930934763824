/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import {
    CustomersService as ICustomersService,
    GlobalConfigurationService as IGlobalConfigurationService,
    ModelFieldValueTranslationsService as IModelFieldValueTranslationsService,
    PlansService as IPlansService,
    PricesService as IPricesService,
    ProductsService as IProductsService,
    Service,
    SubscriptionsService as ISubscriptions,
    Users as IUsers
} from "App/Contracts";

import { Coupon, PriceTarget } from "App/Models";

import { DataStore } from "App/IOC";

import BaseService from "App/Externals/Services/Base";
import CustomersService from "App/Externals/Services/Customers";
import ModelFieldValueTranslationsService from "App/Externals/Services/ModelFieldValueTranslations";
import PlansService from "App/Externals/Services/Plans";
import PricesService from "App/Externals/Services/Prices";
import ProductsService from "App/Externals/Services/Products";
import SubscriptionsService from "App/Externals/Services/Subscriptions";
import UsersService from "App/Externals/Services/Users";
import { Services } from "App/Externals/IOC";
import GlobalConfiguration from "App/Externals/Services/GlobalConfiguration";
import AdsPublicationService from "App/Externals/Services/AdsPublication";

const service = "Internal";

const Customers: ICustomersService = new CustomersService( Services.Get( `${service}/Customers` ) );
const Products: IProductsService = new ProductsService( Services.Get( `${service}/Products` ) );

const Plans: IPlansService = new PlansService(
    Services.Get( `${service}/Plans` ),
    Services.Get( `${service}/Plans/Duplication` )
);

const Prices: IPricesService = new PricesService( Services.Get( `${service}/Prices` ) );
const Users: IUsers = new UsersService(
    Services.Get( "Authentication" ),
    Services.Get( `${service}/Users/Current` ),
    DataStore
);

const Subscriptions: ISubscriptions = new SubscriptionsService(
    Services.Get( `${service}/Subscriptions` ),
    Services.Get( `${service}/Subscriptions/GroupTargetLines` ),
    Services.Get( `${service}/Subscriptions/Unsubscribe` )
);

const Coupons: Service<Coupon> = new BaseService( Services.Get( `${service}/Coupons` ) );

const GlobalConfigurationService: IGlobalConfigurationService = new GlobalConfiguration(
    Services.Get( `${service}/GlobalConfiguration` )
);

const PriceTargets: Service<PriceTarget> = new BaseService(
    Services.Get( `${service}/PriceTargets` )
);

const ModelFieldValueTranslations: IModelFieldValueTranslationsService =
    new ModelFieldValueTranslationsService( Services.Get(
        `${service}/ModelFieldValueTranslations` )
    );

const AdsPublications =
    new AdsPublicationService( Services.Get( "Configuration/Gateway/GatewayFtp" ), 
        Services.Get( "Configuration/Gateway/Picture" ),
        Services.Get( "Configuration/Gateway/Customer" ) );

export {
    Coupons,
    AdsPublications,
    Customers,
    GlobalConfigurationService,
    ModelFieldValueTranslations,
    Products,
    Plans,
    Prices,
    PriceTargets,
    Subscriptions,
    Users
};
