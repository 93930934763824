/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Configuration from "./Configuration";
import Coupons from "./Coupons";
import Customers from "./Customers";
import Products from "./Products";
import Plans from "./Plans";
import Prices from "./Prices";
import PriceTargets from "./PriceTargets";
import Subscriptions from "./Subscriptions";
import AdsPublication from "./AdsPublication";

export default {
    GLOBAL_appBrand: process.env.APP_BRAND || "",

    GLOBAL_defaultDateFormat: "D MMM YYYY",
    GLOBAL_defaultDateTimeFormat: "D MMM YYYY à HH:mm",
    GLOBAL_decimalSeparator: ",",
    GLOBAL_decimalThousandSeparator: " ",
    GLOBAL_default_standard_date_format: "DD/MM/YYYY",
    GLOBAL_defaultStandardDateTimeFormat: "DD/MM/YY HH:mm",

    GLOBAL_sessionExpired: "Votre session est expirée. Veuillez vous reconnecter.",

    SIGNIN_login: "S'identifier",
    SIGNIN_password: "Mot de passe",
    SIGNIN_logIn: "S'identifier",
    SIGNIN_forgotPassword: "Mot de passe oublié?",
    SIGNIN_showPassword: "Afficher le mot de passe",
    SIGNIN_hidePassword: "Cacher le mot de passe",
    SIGNIN_email: "Email",

    GLOBAL_configuration: "Configuration",
    GLOBAL_products: "Produits",
    GLOBAL_PRICE_TARGETS: "Type de prix",
    MAIN_MENU_dashboard: "Tableau de bord",
    MAIN_MENU_products: "Produits",
    MAIN_MENU_plans: "Plans",
    MAIN_MENU_coupons: "Bons de réduction",
    MAIN_MENU_priceTargets: "Type de prix",
    MAIN_MENU_customers: "Clients",
    MAIN_MENU_configuration: "Configuration",
    MAIN_MENU_subscriptionPlans: "Plans d'abonnements",
    MAIN_MENU_subscriptions: "Abonnements",
    MAIN_MENU_invoicing: "Facturation",
    MAIN_MENU_adsPublication: "Portails d’annonces",

    GLOBAL_warning: "Avertissement",
    GLOBAL_fillRequiredField: "Ce champs est requis",
    GLOBAL_cancel: "Annuler",
    GLOBAL_internalError: "Erreur interne du serveur",

    GLOBAL_tableActionSelected: "sélectionné(e)",
    GLOBAL_tableActionMultiSelected: "sélectionné(e)s",

    GLOBAL_currency: "€",
    GLOBAL_percentage: "%",
    GLOBAL_total: "Total",
    GLOBAL_unknown: "Inconnu",
    GLOBAL_actionDone: "J'ai terminé",

    GLOBAL_confirm: "Confirmer",
    GLOBAL_yes: "Oui",
    GLOBAL_no: "Non",
    GLOBAL_formSave: "Enregistrer",
    GLOBAL_next: "Suivant",
    GLOBAL_actions: "Actions",

    GLOBAL_Product: "Produit",
    GLOBAL_CUSTOMER: "Client",
    GLOBAL_COUPON: "Bons de réduction",
    GLOBAL_Price: "Prix",

    GLOBAL_attention: "Attention",
    GLOBAL_actionArchive: "Archiver",
    GLOBAL_actionDelete: "Supprimer",
    GLOBAL_actionUpdate: "Modifier",

    GLOBAL_statusActive: "Actif",
    GLOBAL_statusArchived: "Archivé",

    GLOBAL_Language: "Langue",
    GLOBAL_authenticationProblem: "L'utilisateur n'est pas authentifié",

    GLOBAL_filterName: "Nom",
    GLOBAL_filterStatus: "État",
    GLOBAL_filterStatusPlaceholder: "État",

    GLOBAL_value: "Valeur",
    GLOBAL_confirmAction: "Oui, je confirme",

    FILTER_or: " <b>ou</b> ",
    FILTER_and: "<b>et</b>",
    FILTER_add: "Ajouter un filtre",
    FILTER_apply: "Appliquer",
    FILTER_contains: "Contient",
    FILTER_value: "Valeur",
    FILTER_filterBy: "Filtrer par {0}",
    FILTER_and_x_more: " et {0} autres",
    FILTER_propertyTagsId: "tag",
    FILTER_propertyTypeId: "type",
    FILTER_propertySubTypeId: "sous-type",

    GLOBAL_noResults: "Aucun résultat",

    GLOBAL_fieldSequence: "Séquence",

    GLOBAL_configurationCoupons: "Bons de réduction",

    CONFIGURATION_navigationGeneral: "Général",
    CONFIGURATION__yearlyCoupon: "Bon de réduction annuelle",

    TRANSLATIONS_en: "Anglais",
    TRANSLATIONS_fr: "Français",
    TRANSLATIONS_es: "Espagnole",
    TRANSLATIONS_translated: "Traduit",
    TRANSLATIONS_notTranslated: "Non traduit",
    TRANSLATIONS_title: "Traduction de la valeur",
    TRANSLATIONS_notificationTitle: "Traduction",
    TRANSLATIONS_updateTitle: "Modifier une traduction",
    TRANSLATIONS_updated: "Traduction mise à jour avec succés",


    ...Configuration,
    ...Coupons,
    ...Customers,
    ...Products,
    ...Plans,
    ...Prices,
    ...PriceTargets,
    ...Subscriptions,
    ...AdsPublication
};
