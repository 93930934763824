


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { $t } from "App/IOC";
import { Field, CustomError } from "App/Models";
import { ICustomDate } from "App/Contracts";

import { Option, OptionRequest } from "Web/Contracts";

import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import DateField from "Web/Components/Common/Input/Date.vue";
import DateTimeField from "Web/Components/Common/Input/DateTime.vue";
import EmailField from "Web/Components/Common/Input/Email.vue";
import Form from "Web/Components/Form/Index.vue";
import FormNumberField from "Web/Components/Common/Input/FormNumber.vue";
import Number from "Web/Components/Common/Input/Number.vue";
import PasswordField from "Web/Components/Common/Input/Password.vue";
import SimpleSelect2 from "Web/Components/V2/Select/Simple.vue";
import TextField from "Web/Components/Common/Input/Text.vue";
import FileUpload from "Web/Components/Common/Input/FileUpload.vue";
import TimeField from "Web/Components/Common/Input/Time.vue";
import Translation from "Web/Components/Translation.vue";
import AutocompleteSelect from "Web/Components/V2/Select/Autocomplete.vue";

@Component({
    components: {
        AutocompleteSelect,
        BooleanField,
        DateField,
        DateTimeField,
        EmailField,
        NumberField: FormNumberField,
        Number,
        PasswordField,
        SimpleSelect2,
        TextField,
        FileUpload,
        TimeField,
        Translation
    }
})
export default class FormField extends Vue {
    @Prop() public Metadata!: Field;

    @Prop( { default: true } )
    Label!: boolean;

    @Prop( { default: "" } )
    Placeholder!: string;

    @Prop()
    GetOptions!: Option[] | OptionRequest;

    @Prop()
    Options!: Option[];

    /* Delegate filtering operation to parent component */
    @Prop( { default: false } )
    Filter!: boolean;

    @Prop( { default: "Standard" } )
    Component!: string;

    @Prop( { default: false } )
    Readonly!: boolean;

    @Prop()
    ClassName?: string;

    @Prop( { default: "" } )
    Id!: string;

    @Prop( { default: () => undefined } )
    Validator!: () => CustomError | undefined;

    @Prop( { default: false } )
    Disabled!: boolean;

    @Prop()
    ForceValue?: any;

    @Prop( { default: false } )
    readonly CanCreate!: boolean;

    @Prop()
    MinDate?: ICustomDate;

    /* TODO: Is there a way we can make this strongly typed? */
    Value: any = {};

    /* In case we wanted to implement differences checking in a particular field
       instead of the default method on the Form component, we need to have a reference
       to the field value on the record itself. */
    OriginalValue: any = {};

    private validation = {} as CustomError | undefined;

    private form?: Form = {} as Form;

    async created() {
        this.form = undefined;

        this.Value = undefined;
        this.validation = undefined;
    }

    @Watch( "ForceValue" )
    private onForceValue( value: any ): void {
        this.update( value, undefined );
    }

    private mounted() {
        if( this.form === undefined ) {
            let parent = this.$parent;

            /* TODO: Can we optimize this and make it O(1) ? */
            while( parent !== undefined ) {
                if( parent instanceof Form ) {
                    this.form = parent as Form;
                    break;
                }

                parent = parent.$parent;
            }
        }

        if( this.form === undefined ) {
            console.warn( "No parent form was found for field ", this.Metadata.Id );
            return;
        }

        this.form.Register( this );
    }

    private update( value: any, validation: CustomError | undefined ) {
        this.Value = value;

        this.$emit( "Value", this.Metadata, value );

        if( this.Validator !== undefined )
            this.validation = this.Validator();
        else
            this.validation = validation || this.defaultValidator();
    }

    private beforeDestroy() {
        if( this.form !== undefined )
            this.form.Unregister( this );
    }

    /* Hack, V2 SimpleSelect component separates option request and options array into
       two props, while Field component is built with classic SimpleSelect component in mind. */
    private getSelectedValueFromPrimitive(): Option | undefined {
        let selectedValue;

        let options: Option[];

        if( this.GetOptions !== undefined )
            /* HACK: This is supposed to be used with none Promise based GetOptions. */
            // @ts-ignore
            options = this.GetOptions();
        else
            options = this.Options;

        options.forEach( ( option: any ) => {
            if( option.Value === this.Value )
                selectedValue = option;
        });

        return selectedValue;
    }

    private defaultValidator(): CustomError | undefined {
        switch( true ) {
            case this.Metadata.Required && this.Value === undefined:
                return new CustomError( "", $t( "VALIDATION_required" ) );

            default:
                return undefined;
        }
    }
}
