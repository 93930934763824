


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

import { AdsPublications } from "App";

import { $t, EventBus } from "App/IOC";
import { CountDifferentFields } from "App/Externals/Services/Base";

import {
    CustomNotification,
    Field,
    PagerSorter,
    TextFilter
} from "App/Models";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";
import { CustomError } from "@IBS/Frontend/Models";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        PopupWindow
    }
})
export default class GatewayFtpForm extends Vue {
    @Prop() readonly row?: any;

    @Ref( "form" )
    private readonly form?: Form;

    private fields: Dictionary<Field> = {};

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: $t( "ADS_PUBLICATION_confirmCreate" ),
        Active: true
    };
    private record: any = {};
    private fileObj: any = null;
    private imageUrl: string = "";
    private isImageDeleted: boolean = false;

    private async beforeCreate(): Promise<void> {
        this.fields = await AdsPublications.Fields.Get();
    }

    private async onFormSubmit(): Promise<void> {
        if( this.form === undefined )
            return;

        this.action.Active = false;

        const record: any | undefined = this.form.GetRecord() as any;

        
        if(this.row !== undefined && this.record !== undefined 
            && this.row !== null && this.row.Id !== undefined) {
            await this.update(record);   
        }
        else {
            await this.create(record);
        }
    }


    private async fileToBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Read file as Data URL (Base64)
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    private async create(record: any): Promise<void> {
        if(this.fileObj) {
            const base64 = await this.fileToBase64(this.fileObj);
            record.File = base64;
        }       
        const [ createdRecord, error ] = await AdsPublications.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "ADS_PUBLICATION_createSuccessTitle" ),
                $t( "ADS_PUBLICATION_createSuccessMessage" )
            )
        );

        const createdData: any = createdRecord as any;
        if( createdData !== undefined && createdData.id !== undefined ) {
            await this.syncGroups(record, createdData.id as number );
        }             
        this.$emit( "Create", createdRecord );
    }

    private async syncGroups(record: any, id: number, isUpdate: boolean = false): Promise<void> {
        const differentFields = CountDifferentFields(this.record, record);
        if( Object.keys( differentFields as {} ).length === 0 
        || differentFields.Customers === undefined ) {
            EventBus.Publish(
                "Notification",
                CustomNotification.Success(
                    $t( "ADS_PUBLICATION_createSuccessTitle" ),
                    $t( "GLOBAL_nothingToUpdate" )
                )
            );
            return;
        }
        if(differentFields.Customers !== undefined && differentFields.Customers.length > 0) {
            if(isUpdate) {
                for(const item of this.record.Customers) {
                    const data: any = {
                        Id: id,
                        GroupId: item.Group
                    };
                    // eslint-disable-next-line max-len
                    const [ result, error ] = await AdsPublications.DeleteCustomer(this.record.Id, data );
                    console.log("result", result);
                    if( error !== undefined ) {
                        EventBus.Publish( "Error", error );
                        this.action.Active = true;
                        this.canCreate();
                    }
                }
            }

            for(const item of record.Customers) {
                const data: any = {
                    Id: id,
                    GroupId: item.Group
                };
                const [ result, error ] = await AdsPublications.CustomerCreate( data);
                console.log("result", result);

                if( error !== undefined ) {
                    EventBus.Publish( "Error", error );
                    this.action.Active = true;
                    this.canCreate();
                }
            }

            EventBus.Publish(
                "Notification",
                CustomNotification.Success(
                    $t( "ADS_PUBLICATION_createSuccessTitle" ),
                    isUpdate ? $t( "ADS_PUBLICATION_UpdateCustomerMessage" )
                        : $t( "ADS_PUBLICATION_createCustomerMessage" )
                )
            );
        }

    }

    private async update(record: any): Promise<void> {
        if(this.fileObj) {
            const base64 = await this.fileToBase64(this.fileObj);
            record.File = base64;
            if(this.record.Image) {
                // eslint-disable-next-line max-len
                const [ result, error ] = await AdsPublications.DeletePicture( this.record.Id );
                console.log(result);
                if( error !== undefined ) {
                    EventBus.Publish( "Error", error );
                }
            }
        } else if(this.isImageDeleted) {
            // eslint-disable-next-line max-len
            const [ result, error ] = await AdsPublications.DeletePicture( this.record.Id );
            console.log(result);
            if( error !== undefined ) {
                EventBus.Publish( "Error", error );
            }
            record.File = undefined;

            const differentFields = CountDifferentFields(this.record, record);
            if( Object.keys( differentFields as {} ).length === 0 ) {
                EventBus.Publish(
                    "Notification",
                    CustomNotification.Success(
                        $t( "ADS_PUBLICATION_createSuccessTitle" ),
                        $t( "ADS_PUBLICATION_createUpdateMessage" )
                    )
                );

                this.$emit( "Create", record );
                return;
            }
        }  
        

        const [ createdRecord, error ] = await AdsPublications.Update( this.record, record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "ADS_PUBLICATION_createSuccessTitle" ),
                $t( "ADS_PUBLICATION_createUpdateMessage" )
            )
        );

        await this.syncGroups(record, this.record.Id as number, true );
        this.$emit( "Create", createdRecord );
    }

    private canCreate(): void {
        if( this.form === undefined )
            return;

        this.action.Active = true;
        // const record = this.form.GetRecord() as any;
        // this.action.Active = record.Name !== undefined && record.Host !== undefined;
    }

    private async getGatewayFormatOptions(): Promise<[any[], undefined]> {
        const result = AdsPublications.PlaceholderData.getGatewayFormatOptions();
        return [ result, undefined ];
    }

    private async getEncodingOptions(): Promise<[any[], undefined]> {
        const result = AdsPublications.PlaceholderData.getEncodingOptions();
        return [ result, undefined ];

    }

    @Watch( "row", { immediate: true } )
    private async onRowChanged(): Promise<void> {
        if( this.row === undefined || this.row === null || this.row === "" ) return;
        const id = this.row.Id ? this.row.Id : this.row.id; 
        this.action.Name = id !== undefined && id !== "" ?
            $t( "ADS_PUBLICATION_confirmUpdate" ) : $t( "ADS_PUBLICATION_confirmCreate" );
        const [ record, error ] = await AdsPublications.Get( id );
        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        const formatedRecord = this.capitalizeKeys( record );


        if(formatedRecord.Distant_folder !== undefined) {
            formatedRecord.DistantFolder = formatedRecord.Distant_folder;
            delete formatedRecord.Distant_folder;
        }

        if(formatedRecord.Password !== undefined) {
            formatedRecord.Password = {
                Value: formatedRecord.Password
            };
        }

        if(formatedRecord.Serializer !== undefined) {
            formatedRecord.SerializerId = 
                formatedRecord.Serializer ? formatedRecord.Serializer.id : undefined;
        }
        if(formatedRecord.Format !== undefined) {
            const formatOptions = AdsPublications.PlaceholderData.getGatewayFormatOptions();
            if( formatOptions !== undefined ) {
                formatedRecord.Format = 
                    formatOptions.find( x => x.Id === formatedRecord.Format );
            }
        }

        if(formatedRecord.Encoding !== undefined) {
            const encodingOptions = AdsPublications.PlaceholderData.getEncodingOptions();
            if( encodingOptions !== undefined ) {
                formatedRecord.Encoding = 
                    encodingOptions.find( x => x.Id === formatedRecord.Encoding );
            }
        }
        if(formatedRecord.Customers !== undefined && formatedRecord.Customers.length > 0) {
            formatedRecord.Customers = formatedRecord.Customers.map(function(item: any) {
                return {
                    Group: item.group,
                    Id: item.id,
                    Name: item.name
                };
            });
        }

        this.record = formatedRecord;

        if(this.record !== undefined && this.record.Image !== undefined) {
            this.imageUrl = this.record.Image;
        }
    }

    private capitalizeKeys(obj: any): Record<string, any> {
        const newObj: Record<string, any> = {};
        for(const key in obj) {
            if(obj.hasOwnProperty(key)) {
                const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                newObj[capitalizedKey] = obj[key];
            }
        }
        return newObj;
    }

    private async handleImageUpload(event: any): Promise<void> {
        const file = event.target.files[0];
        if(!file) return;
        this.fileObj = file;
        this.imageUrl = URL.createObjectURL(file);
    }
    private async handleImageDelete(): Promise<void> {
        if(this.record && this.record.Id && this.record.Image) {

            EventBus.Publish("Confirmation", {
                Props: {
                    Title: this.$t("GLOBAL_attention"),

                    Message: this.$tc("ADS_PUBLICATION_deleteImageConfirm"),

                    Actions: [{
                        Id: "endTrial",
                        Name: this.$t("GLOBAL_actionDelete"),
                        Active: true
                    }]
                },
                Events: {
                    Action: async () => {
                        // eslint-disable-next-line max-len
                        // const [ result, error ] = await AdsPublications.DeletePicture( this.record.Id );

                        // if( error !== undefined ) {
                        //     EventBus.Publish( "Error", error );
                        //     this.action.Active = true;
                        //     this.canCreate();
                        //     return;
                        // }
                        // EventBus.Publish(
                        //     "Notification",
                        //     CustomNotification.Success(
                        //         $t( "ADS_PUBLICATION_createSuccessTitle" ),
                        //         $t( "ADS_PUBLICATION_createDeleteMessage" )
                        //     )
                        // );
                        this.fileObj = null;
                        this.imageUrl = "";
                        this.isImageDeleted = true;
                        // this.$emit( "Create", result );

                    }
                }
            });
           
        } else {
            this.fileObj = null;
            this.imageUrl = "";
        }
    }

    private async getCustomers( keyword?: string ): Promise<[ any[], CustomError | undefined ]> {
        if( keyword === undefined )
            return [ [], undefined ];

        const filter = new TextFilter( "Name", "" );
        filter.Value = [ keyword ];

        const [ result, , error ] = 
        await AdsPublications.GetAllCustomers( [filter], new PagerSorter( 999 ) );

        return [ result, error ];
    }
}
